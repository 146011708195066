<template>
  <div>
    <!-- Show the 'listProjects' view. -->
    <div v-if="adminView === 'listProjects'">
      <!-- Import 'Navbar' component with parameters. -->
      <Navbar
        :active="activeProject"
        @change="setDefaultView"
        @edit-project="openModal('edit-project')"
        @delete-project="openModal('delete-project')"
        @delete-topic="openModal('delete-topic')"
        :projectSettings="adminView"
        ref="navbar"
      >
      </Navbar>
       <b-container fluid style="height:100vh"  >
        <b-row  class="h-100" >
          <b-col cols="12" >
          <!-- Import 'TheAdminActiveProject' component with parameters. -->
          <AdminTopicModelingDashboard
            :active="activeProject"
            ref="topics"
          />
          </b-col>
        </b-row>  
      </b-container>
      <!-- Modal to create a new project. -->
      <AdminModal
        @cancel-action="closeModal"
        @confirm-action="createProject"
        :activeUser="activeUser"
        :createProject="true"
        description="admin.modals.description.label"
        id="create-project"
        name="admin.modals.name.label"
        ref="modal_1"
        title="admin.modals.create.project.title"
        :type="$t('admin.projects.type')"
      />
      <!-- Modal to edit the active project. -->
      <AdminModal
        :activeProject="activeProject"
        @cancel-action="closeModal"
        @confirm-action="editProject"
        description="admin.modals.description.label"
        :editProject="true"
        id="edit-project"
        name="admin.modals.name.label"
        title="admin.modals.edit.project.title"
      />
      <!-- Alert for changes being discarded. -->
      <Alert
        :message="$t(message)"
        ref="alert"
      />
      <!-- Modal to warn about changes to the active topic. -->
      <AdminModal
        @cancel-action="discardChanges"
        :changesWarning="true"
        @confirm-action="saveChanges(editedItems)"
        id="changes-warning"
        title="admin.modals.delete.title"
      />
    </div>
  </div>
</template>

<script>
import axios from "axios"; // TODO: refactor needed
import bus from "@/bus";
import cookies from "@/common/cookies";
import _ from "lodash";

import {
  AdminModal,
  Alert,
  AdminSidebar,
  AdminTopicModelingDashboard,
  Navbar
} from "@/components";

export default {
  name: "Admin",
  components: {
    AdminModal,
    Alert,
    AdminSidebar,
    AdminTopicModelingDashboard,
    Navbar
  },
  data() {
    return {
      activeProject: {},
      activeUser: {},
      adminView: "noProjects",
      editedItems: [],
      isEdited: false,
      message: "Alert.Discard",
      projects: {},
    }
  },
  created() {
    this.authorizeUser();
    bus.$on("edited", item => {
      this.editedItems.push(item);
      this.isEdited = true;
    });
    bus.$on("saved", () => {
      this.isEdited = false;
    });
  },
  methods: {
    /**
     * Change the active project.
     * @param {number} index - Index of the new active project in the projects list.
     */
    changeActiveProject(index) {
      if (this.$refs.navbar.isEdited) {
        this.openModal("changes-warning");
        return;
      }
      this.activeProject = this.projects[index];
      this.$refs.topics.getActiveProjectTopics(this.activeProject.id);
      this.$refs.topics.getFilesList(this.activeProject.id);
      this.$store.commit("setNavbar", { // TODO: refactor needed
        generateBot: false,
        theActiveProjectTitle: false
      });
      this.$store.commit("setTheDefaultView", "topicsView"); // TODO: refactor needed
    },
    /**
     * Close the selected modal.
     * @param {string} modal - ID of the selected modal.
     */
    closeModal(modal) {
      this.$bvModal.hide(modal);
      if (modal === "create-project" && this.adminView === "noProjects") {
        this.welcomeMessage("show");
      }
    },
    /**
     * Discard changes to questions and answers, then close the active modal.
     */
    discardChanges() {
      /**
       * Discard changes to questions and answers.
       */
      this.$refs.topics.updateQasList(this.$refs.topics.activeTopicId);
      this.editedItems = [];
      /**
       * Close the active modals.
       */
      this.closeModal("changes-warning");
      bus.$emit("saved");
      this.$refs.alert.showAlert();
      // TODO: refactor needed
      this.$store.commit("setNavbar", {
        generateBot: false,
        saveActiveTopic: false,
        theActiveProjectTitle: false,
        trainBot: false
      });
      this.$store.commit("setTheDefaultView", "topicsView");
    },
    /**
     * Log the active user out and dismiss saved credentials.
     */
    handleLogout() {
      if (localStorage || sessionStorage) {
        localStorage.removeItem("jwt") || sessionStorage.removeItem("jwt");
      } else {
        this.cookies.deleteCookie("jwt");
      }
      this.$store.commit("setAuth", false);
      this.$store.commit("setNavbar", { // TODO: refactor needed
        generateBot: false,
        saveActiveTopic: false,
        trainBot: false
      });
      this.$router.push("/login");
    },
    /**
     * Open the selected modal.
     * @param {string} modal - ID of the selected modal.
     */
    openModal(modal) {
      if (modal === "create-project" && this.adminView === "noProjects") {
        this.welcomeMessage("hide");
      } else if (this.$refs.navbar.isEdited) {
        this.$bvModal.show("changes-warning");
        return;
      }
      this.$bvModal.show(modal);
    },
    /**
     * Set the 'topicsView' as the default view for the active project.
     */
    setDefaultView() {
      if (this.$refs.navbar.isEdited) {
        this.openModal("changes-warning");
        return;
      }
      this.$store.commit("setNavbar", {
        generateBot: false,
        theActiveProjectTitle: false
      });
      this.$store.commit("setTheDefaultView", "topicsView");
      this.$refs.topics.getActiveProjectTopics(this.activeProject.id);
    },
    /**
     * Switch the welcome message visibility.
     * @param {string} visibility - Visibility of the welcome message: accept 'show' or 'hidden'.
     */
    welcomeMessage(visibility) {
      const welcome = document.getElementById("welcome");
      visibility === "show" ? welcome.style.visibility = "visible" : welcome.style.visibility = "hidden";
    },

    // TODO: refactor needed

    /**
     * Authorize the user by either `window.localStorage', `window.sessionStorage`, or a cookie-stored token as a fallback.
     */
    authorizeUser() {
      let token;
      if (localStorage || sessionStorage) {
        token = localStorage.getItem("jwt") ? localStorage.getItem("jwt") : sessionStorage.getItem("jwt");
      } else {
        token = cookies.getCookie("jwt");
      }
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    },

    /**
     * Get active user details
     */
    async getActiveUser() {
      let id;
      if (localStorage || sessionStorage) {
        id = localStorage.getItem("id") ? localStorage.getItem("id") : sessionStorage.getItem("id");
      } else {
        id = cookies.getCookie("id");
      }

      try {
        const res = await axios.get(`/user/${id}`);
        if (res.status === 200) {
          this.activeUser = res.data.data;
        }
      }
      catch {}
    },

    /**
     * Get the existing projects list, then show it.
     */
    async getProjects(id) {
      await axios
              .get(`/user/${this.activeUser.id}/projects`)
                .then(res => {
                  this.projects = res.data.data.projects;
                  if (this.projects.length) {
                    this.$store.commit("setNavbar", {
                      generateBot: false,
                    });
                    var parsedobj = JSON.parse(JSON.stringify(this.projects))
                    for (let i = 0; i < parsedobj.length; i++) {
                      var obj = parsedobj[i];
                      if(obj.id == this.$route.params.project_id){
                        this.activeProject = this.projects[i];
                        break
                      }
                    }
                    this.adminView = "listProjects";
                  } else {
                    this.activeProject = {};
                    this.adminView = "noProjects";
                  }
                });
    },
    async updateProjects(created, deleted) {
      await axios
              .get(`/user/${this.activeUser.id}/projects`)
                .then(res => {
                  this.projects = res.data.data.projects;
                  if (this.projects.length) {
                    this.$store.commit("setNavbar", {
                      generateBot: false,
                    });
                    if (created) {
                      this.activeProject = this.projects[this.projects.length - 1];
                    } else if (deleted) {
                      this.activeProject = this.projects.length > 1 ? this.projects[this.projects.length - 1] : this.projects[0];
                    } else {
                      this.activeProject = this.activeProject;
                    }
                    this.adminView = "listProjects";
                    // TODO: refresh topics
                  } else {
                    this.activeProject = {};
                    this.$store.commit("setNavbar", {
                      generateBot: false,
                    });
                    this.adminView = "noProjects";
                  }
                });
    },
    /**
     * Create a new project, then show it.
     * @param {string} description - Description of the new project (can be empty).
     * @param {string} name - Name of the new project.
     */
    async createProject({
      description,
      name
    }) {
      const user_id = this.activeUser.id;
      await axios
              .put("/project", {
                description,
                name,
                user_id
              })
                .then(async res => {
                  this.closeModal("create-project");
                  await this.updateProjects(true);
                  
                  // Temporary workaround to avoid calling be twice when creating the first project
                  if (this.projects.length > 1) {
                    this.$refs.topics.getActiveProjectTopics(this.activeProject.id);
                    this.$refs.topics.getFilesList(this.activeProject.id);
                  }
                });
    },
    /** 
     * Edit the active project, then show the changes.
     * @param {string} description - New description of the active project (can be empty or unchanged).
     * @param {string} name - New name of the active project (can be unchanged).
     */
    async editProject({
      description,
      name
    }) {
      await axios
              .post(`/project/${this.activeProject.id}`, {
                description,
                name
              })
                .then(() => {
                  this.closeModal("edit-project");
                  this.getProjects();
                });
    },
    /**
     * Save local questions and/or answers changes to the database.
     * @param {array} items - Array of edited questions and/or answers.
     */
    async saveChanges(items) {
      if (!items) return;

      let topic_id, text, is_training, is_selected;

      for (let item of items) {
        if (item.type === "question") {

          topic_id = item.topic_id;
          text = item.text;
          is_training = true;
          await axios
                .post(`/question/${item.id}`, {
                  topic_id,
                  text,
                  is_training
                })
                  .then(() => {
                    this.editedItems = [];
                    this.closeModal("changes-warning");
                    bus.$emit("saved");
                    this.message = "Alert.Save";
                    this.$refs.alert.showAlert();
                    // TODO: refactor needed
                    this.$store.commit("setNavbar", {
                      generateBot: false,
                      saveActiveTopic: false,
                      theActiveProjectTitle: false,
                      trainBot: false
                    });
                    this.$store.commit("setTheDefaultView", "topicsView");
                  });
        } else {
          topic_id = item.topic_id;
          text = item.text;
          is_selected = true;
          await axios
                .post(`/answer/${item.id}`, {
                  topic_id,
                  text,
                  is_selected
                })
                  .then(() => {
                    this.editedItems = [];
                    this.closeModal("changes-warning");
                    bus.$emit("saved");
                    this.message = "Alert.Save";
                    this.$refs.alert.showAlert();
                    // TODO: refactor needed
                    this.$store.commit("setNavbar", {
                      generateBot: false,
                      saveActiveTopic: false,
                      theActiveProjectTitle: false,
                      trainBot: false
                    });
                    this.$store.commit("setTheDefaultView", "topicsView");
                  });
        }
      }
    }
  },
  async beforeMount() {
    await this.getActiveUser();
    this.getProjects();
    this.$store.commit("setSalesForceView", true);
  },
  mounted() {
    /**
     * Reset the active modals inputs.
     * @event bvModalEvt
     * @param {string} modalId - ID of the selected modal.
     */
    this.$root.$on("bv::modal::hide", (bvModalEvt, modalId) => {
      if (this.$refs.modal_1) {
        this.$refs.modal_1.form = {};
      }
      if (this.$refs.modal_2) {
        this.$refs.modal_2.form = {};
      }
    });
  }
};
</script>